import React from 'react';
import SkeletonLoader from '../../components/SkeletonLoader';
import NotificationEmptyState from '../../components/NotificationEmptyState';

export default ({ loading, empty, emptyText = 'ไม่พบรายการ', children, colorText='' }) => {
  if (empty) {
    return (
      <NotificationEmptyState compact>
        <h3 style={{ color: colorText ? colorText : '#4677a8'}}>{emptyText}</h3>
      </NotificationEmptyState>
    )
  }
  if (loading) {
    return <SkeletonLoader />;
  }
  return children;
}
