import * as Constant from './constants';
import * as Api from '../apis';
import { PaymentType } from '../helpers/constants';
import { createSelectedItemsSelector } from '../reducers/lab';
import { updateMe } from './session';

export const getLabList = (type, latitude, longitude) => {
  return dispatch => {
    dispatch({ type: Constant.LAB_LIST_GET_REQUEST });
    return Api.getLabList(type, latitude, longitude)
      .then(payload => {
        dispatch({ type: Constant.LAB_LIST_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.LAB_LIST_GET_FAILED, message });
      });
  };
};

export const getLabOrders = type => {
  return dispatch => {
    dispatch({ type: Constant.LAB_ORDERS_GET_REQUEST });
    return Api.getLabOrders(type)
      .then(payload => {
        dispatch({ type: Constant.LAB_ORDERS_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.LAB_ORDERS_GET_FAILED, message });
      });
  };
};

export const getLabOrder = id => {
  return dispatch => {
    dispatch({ type: Constant.LAB_ORDER_GET_REQUEST });
    return Api.getLabOrder(id)
      .then(payload => {
        dispatch({ type: Constant.LAB_ORDER_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.LAB_ORDER_GET_FAILED, message });
      });
  };
};

export const checkoutLab = () => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.LAB_ORDER_POST_REQUEST });
    const order = createSelectedItemsSelector(getState());

    const { orderInfo, session } = getState();
    const {
      name,
      phone,
      slotTimeId,
      remark,
      address,
      payment,
      medicallabBranch,
      promotionId,
    } = orderInfo;

    const _name = name ? name.split(' ') : [];
    const firstName = _name[0];
    const lastName = _name[1];

    const val = {
      address: {
        ...address,
        is_save: !session.user || !(address && address.id),
        customer_name: name,
        phone: phone,
      },
      package_items: order.items.map(item => ({
        labpackage: item.product.id,
      })),
      time_slot_id: slotTimeId,
      remark: remark,
      medicallab_branch: medicallabBranch,
      promotion_id: promotionId,
    };

    if (payment && typeof payment === 'object') {
      val.checkout_method = {
        method: PaymentType.DEFAULT_CREDIT_CARD,
      };
    } else {
      val.checkout_method = {
        method: payment,
      };
    }

    if (!val.checkout_method) {
      return;
    }

    return Promise.resolve()
      .then(() => {
        if (!firstName && !lastName) return Promise.reject('กรุณากรอกชื่อ');
        if (!phone) return Promise.reject('กรุณากรอกเบอร์โทร');
        if (!address) return Promise.reject('กรุณาระบุที่อยู่');
        if (!payment) return Promise.reject('กรุณาเลือกช่องทางการชำระเงิน');
        if (!slotTimeId) return Promise.reject('กรุณาเลือกวันที่และเวลา');
        if (!session.user && !session.line && !session.facebook) {
          return Promise.reject('กรุณาเข้าสู่ระบบ');
        }
        return !session.user.first_name
          ? dispatch(updateMe(firstName, lastName))
          : Promise.resolve();
      })
      .then(() => {
        return Api.postLabOrder(val);
      })
      .then(payload => {
        dispatch({ type: Constant.LAB_ORDER_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.LAB_ORDER_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const checkoutLabSummary = () => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.LAB_ORDER_SUMMARY_POST_REQUEST });
    const order = createSelectedItemsSelector(getState());

    const { orderInfo, session } = getState();
    const {
      name,
      phone,
      slotTimeId,
      remark,
      address,
      payment,
      medicallabBranch,
      promotionId,
    } = orderInfo;

    const val = {
      address: {
        ...address,
        is_save: !session.user || !(address && address.id),
        customer_name: name,
        phone: phone,
      },
      package_items: order.items.map(item => ({
        labpackage: item.product.id,
      })),
      time_slot_id: slotTimeId,
      remark: remark,
      medicallab_branch: medicallabBranch,
      promotion_id: promotionId,
    };

    if (payment && typeof payment === 'object') {
      val.checkout_method = {
        method: PaymentType.DEFAULT_CREDIT_CARD,
      };
    } else if (payment === PaymentType.PROMPTPAY) {
      val.checkout_method = {
        method: PaymentType.PROMPTPAY,
      };
    }

    return Api.postLabOrderSummary(val)
      .then(payload => {
        dispatch({ type: Constant.LAB_ORDER_SUMMARY_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.LAB_ORDER_SUMMARY_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const clearLabItem = () => ({
  type: Constant.LAB_ITEM_CLEAR,
});

export const addLabItem = payload => ({
  type: Constant.LAB_ITEM_ADD,
  payload,
});

export const addLabItemId = id => ({
  type: Constant.LAB_ITEM_ID_ADD,
  payload: {
    id,
  },
});

export const removeLabItemId = id => ({
  type: Constant.LAB_ITEM_ID_REMOVE,
  payload: {
    id,
  },
});

export const setLabIdQuantity = (id, quantity) => ({
  type: Constant.LAB_ITEM_ID_SET_QUANTITY,
  payload: {
    id,
    quantity,
  },
});
