import * as Constant from './constants';
import * as Api from '../apis';
import * as Twilio from '../apis/twilio';
import * as Type from '../helpers/constants';
import * as Daily from '../apis/dailyco';

export const getChannelMessages = channel => {
  return dispatch => {
    dispatch({ type: Constant.MESSAGES_GET_REQUEST });
    return Twilio.getChannelMessages(channel)
      .then(payload => {
        dispatch({ type: Constant.MESSAGES_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.MESSAGES_GET_FAILED, message });
      });
  };
};

export const createTextMessage = (channel, text) => {
  return dispatch => {
    dispatch({ type: Constant.MESSAGE_POST_REQUEST });
    const res = {
      type: Type.ChatMessageType.TEXT,
      value: text,
    };
    return Twilio.createMessage(channel, JSON.stringify(res))
      .then(() => {
        return Twilio.getChannelMessages(channel);
      })
      .then(payload => {
        dispatch({ type: Constant.MESSAGE_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.MESSAGE_POST_FAILED, message });
      });
  };
};

export const createImageMessage = (channel, img) => {
  return dispatch => {
    dispatch({ type: Constant.MESSAGE_POST_REQUEST });
    const formData = new FormData();
    formData.append('file', img);
    return Twilio.createMessage(channel, formData)
      .then(() => {
        return Twilio.getChannelMessages(channel);
      })
      .then(payload => {
        dispatch({ type: Constant.MESSAGE_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.MESSAGE_POST_FAILED, message });
      });
  };
};

export const createVideoMessage = channel => {
  return async dispatch => {
    dispatch({ type: Constant.MESSAGE_POST_REQUEST });
    try {
      const res = await Daily.createVideoRoomWithName(channel.uniqueName);
      if ((res.url != null) & (res.token != null)) {
        const url = `${res.url}?t=${res.token}`;
        const msg = {
          type: Type.ChatMessageType.VIDEO_CALL,
          value: { url },
        };
        await Twilio.createMessage(channel, JSON.stringify(msg));
      }
      let payload = await Twilio.getChannelMessages(channel);
      dispatch({ type: Constant.MESSAGE_POST_SUCCESS, payload });
      return payload;
    } catch (message) {
      dispatch({ type: Constant.MESSAGE_POST_FAILED, message });
    }
  };
};

export const createConsentMedicalMessage = (channel, value) => {
  return async dispatch => {
    dispatch({ type: Constant.MESSAGE_POST_REQUEST });
    try {
      const msg = {
        type: Type.ChatMessageType.CONSENT,
        value: Type.ChatMessageType.MEDICAL_RECORD,
      };
      await Twilio.createMessage(channel, JSON.stringify(msg));
      let payload = await Twilio.getChannelMessages(channel);
      dispatch({ type: Constant.MESSAGE_POST_SUCCESS, payload });
      return payload;
    } catch (message) {
      dispatch({ type: Constant.MESSAGE_POST_FAILED, message });
    }
  };
};
export const createConsentAssessmentMessage = (channel, value) => {
  return async dispatch => {
    dispatch({ type: Constant.MESSAGE_POST_REQUEST });
    try {
      const msg = {
        type: Type.ChatMessageType.CONSENT,
        value: Type.ChatMessageType.ASSESSMENT,
      };
      await Twilio.createMessage(channel, JSON.stringify(msg));
      let payload = await Twilio.getChannelMessages(channel);
      dispatch({ type: Constant.MESSAGE_POST_SUCCESS, payload });
      return payload;
    } catch (message) {
      dispatch({ type: Constant.MESSAGE_POST_FAILED, message });
    }
  };
};

export const getChannelUsers = channel => {
  return dispatch => {
    dispatch({ type: Constant.USERS_DESCRIPTOR_GET_REQUEST });
    return Twilio.getChannelUsers(channel)
      .then(payload => {
        dispatch({ type: Constant.USERS_DESCRIPTOR_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.USERS_DESCRIPTOR_GET_FAILED, message });
      });
  };
};

export const getChatrooms = (isStaff, payment_status) => {
  return dispatch => {
    dispatch({ type: Constant.CHATROOMS_GET_REQUEST });
    return Api.getChatrooms(isStaff, payment_status)
      .then(payload => {
        dispatch({ type: Constant.CHATROOMS_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.CHATROOMS_GET_FAILED, message });
      });
  };
};

export const getChatroom = (isStaff, chatroomId) => {
  return dispatch => {
    dispatch({ type: Constant.CHATROOM_GET_REQUEST });
    return Api.getChatroom(isStaff, chatroomId)
      .then(payload => {
        dispatch({ type: Constant.CHATROOM_GET_SUCCESS, payload });
        if (isStaff && payload.customer && !payload.is_close) {
          dispatch(getChannelPatientRecords(payload.customer.unique_id));
          dispatch(getChannelAppointmentRecords(chatroomId));
          dispatch(getChannelEvaluationRecords(chatroomId));
          dispatch(getChannelLabRecords(chatroomId));
          dispatch(getChannelQuestionaireRecords(chatroomId));
        }
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.CHATROOM_GET_FAILED, message });
      });
  };
};

export const enterChatroom = (isStaff, chatroomId) => {
  return dispatch => {
    dispatch({ type: Constant.CHATROOM_ENTER_REQUEST });
    return Api.postChatroomEnter(isStaff, chatroomId)
      .then(payload => {
        dispatch({ type: Constant.CHATROOM_ENTER_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.CHATROOM_ENTER_FAILED, message });
      });
  };
};

export const closeChatroom = (id, data) => {
  return async dispatch => {
    dispatch({ type: Constant.CHATROOM_CLOSE_REQUEST });
    try {
      const payload = await Api.closeChatroom(id, data);
      await Daily.deleteVideoRoom(id);
      dispatch({ type: Constant.CHATROOM_CLOSE_SUCCESS, payload });
      return payload;
    } catch (message) {
      dispatch({ type: Constant.CHATROOM_CLOSE_FAILED, message });
      return Promise.reject(message);
    }
  };
};

export const getChannelPatientRecords = id => {
  return dispatch => {
    dispatch({ type: Constant.CHATROOM_PATIENT_RECORDS_GET_REQUEST });
    return Api.getPatientRecords(id)
      .then(payload => {
        dispatch({
          type: Constant.CHATROOM_PATIENT_RECORDS_GET_SUCCESS,
          payload,
        });
        return payload;
      })
      .catch(message => {
        dispatch({
          type: Constant.CHATROOM_PATIENT_RECORDS_GET_FAILED,
          message,
        });
      });
  };
};
export const getChannelLabRecords = id => {
  return dispatch => {
    dispatch({ type: Constant.CHATROOM_LAB_RECORDS_GET_REQUEST });
    return Api.getLabRecords(id)
      .then(payload => {
        dispatch({ type: Constant.CHATROOM_LAB_RECORDS_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.CHATROOM_LAB_RECORDS_GET_FAILED, message });
      });
  };
};
export const getChannelAppointmentRecords = id => {
  return dispatch => {
    dispatch({ type: Constant.CHATROOM_APPOINTMENT_RECORDS_GET_REQUEST });
    return Api.getChatroomRecords(id)
      .then(payload => {
        dispatch({
          type: Constant.CHATROOM_APPOINTMENT_RECORDS_GET_SUCCESS,
          payload,
        });
        return payload;
      })
      .catch(message => {
        dispatch({
          type: Constant.CHATROOM_APPOINTMENT_RECORDS_GET_FAILED,
          message,
        });
      });
  };
};
export const getChannelEvaluationRecords = id => {
  return dispatch => {
    dispatch({ type: Constant.CHATROOM_EVALUATION_RECORDS_GET_REQUEST });
    return Api.getEvaluationRecords(id)
      .then(payload => {
        dispatch({
          type: Constant.CHATROOM_EVALUATION_RECORDS_GET_SUCCESS,
          payload,
        });
        return payload;
      })
      .catch(message => {
        dispatch({
          type: Constant.CHATROOM_EVALUATION_RECORDS_GET_FAILED,
          message,
        });
      });
  };
};
export const getChannelQuestionaireRecords = id => {
  return dispatch => {
    dispatch({ type: Constant.CHATROOM_QUESTIONAIRE_RECORDS_GET_REQUEST });
    return Api.getQuestionaireRecords(id)
      .then(payload => {
        dispatch({
          type: Constant.CHATROOM_QUESTIONAIRE_RECORDS_GET_SUCCESS,
          payload,
        });
        return payload;
      })
      .catch(message => {
        dispatch({
          type: Constant.CHATROOM_QUESTIONAIRE_RECORDS_GET_FAILED,
          message,
        });
      });
  };
};

export const getChatroomConsent = id => {
  return dispatch => {
    dispatch({ type: Constant.CHATROOM_CONSENT_GET_REQUEST });
    return Api.getChatroomConsent(id)
      .then(payload => {
        dispatch({ type: Constant.CHATROOM_CONSENT_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.CHATROOM_CONSENT_GET_FAILED, message });
      });
  };
};

export const postChatroomConsent = id => {
  return dispatch => {
    dispatch({ type: Constant.CHATROOM_CONSENT_POST_REQUEST });
    return Api.postChatroomConsent(id)
      .then(payload => {
        dispatch({ type: Constant.CHATROOM_CONSENT_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.CHATROOM_CONSENT_POST_FAILED, message });
      });
  };
};

export const rateChat = (id, data) => {
  return dispatch => {
    dispatch({ type: Constant.CHATROOM_RATE_POST_REQUEST });
    return Api.rateChat(id, data)
      .then(payload => {
        dispatch({ type: Constant.CHATROOM_RATE_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.CHATROOM_RATE_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};
