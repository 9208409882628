import React from 'react';
import { useSelector } from 'react-redux';
import { isSameDate, renderChatTime, renderChatDate, parseAppointmentDateTime } from '../../helpers/date';
import { navigateTo } from '../CrossPlatformLink';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import LabOrderList from '../LabOrderList';
import OrderList from '../OrderList';
import { getDateAppointment } from '../../helpers/date';

export default ({ tab, list = [] }) => {
  const { source } = useSelector(state => state.session);

  const clickChatRoom = (id) => {
    navigateTo(source, `me.moronline://chatroom/${id}`);
  }

  if (tab === 0) {
    return (
      <List>
        {
          list.map((ch, index, array) => {
            const user = ch['staff'];
            const date = getDateAppointment(ch);
            const appointTime = `นัดหมายเวลา ${renderChatTime(date)} น.`;

            let dateElem = <ListItem dense><ListItemText primary={renderChatDate(date)} /></ListItem>;
            const prevCh = array[index - 1];
            if (prevCh && isSameDate(getDateAppointment(prevCh), date)) {
              dateElem = null;
            }

            return (
              <React.Fragment key={ch.id}>
                {dateElem}
                <ListItem
                  style={{ cursor: 'pointer' }}
                  onClick={() => clickChatRoom(ch.id)}
                >
                  <ListItemAvatar>
                    <Avatar src={user?.avatar} />
                  </ListItemAvatar>
                  <ListItemText primary={appointTime} secondary={`${user?.first_name} ${user?.last_name}`} />
                </ListItem>
              </React.Fragment>
            )
          })
        }
      </List>
    );
  }
  if (tab === 1) {
    return (
      <>
        <List>
          {
            list.map((ch) => {
              const user = ch['staff'];
              const date = getDateAppointment(ch);
              const primary = `${renderChatDate(date)} ${renderChatTime(date)} น.`;

              return (
                <React.Fragment key={ch.id}>
                  <ListItem
                    style={{ cursor: 'pointer' }}
                    onClick={() => clickChatRoom(ch.id)}
                  >
                    <ListItemAvatar>
                      <Avatar src={user?.avatar} />
                    </ListItemAvatar>
                    <ListItemText primary={primary} secondary={`${user?.first_name} ${user?.last_name}`} />
                  </ListItem>
                </React.Fragment>
              )
            })
          }
        </List>
      </>
    );
  }
  if (tab === 3) {
    return (
      <LabOrderList
        items={list}
      />
    );
  }
  if (tab === 4) {
    return (
      <LabOrderList
        items={list}
      />
    );
  }
  if (tab === 6) {
    return (
      <OrderList
        items={list}
      />
    );
  }
  return false;
}

