import { PaymentType } from '../helpers/constants';
import { createSelectedMenusSelector } from '../reducers/menus';
import { updateMe } from './session';
import * as Constant from './constants';
import * as Api from '../apis';

export const postOrder = (options, membership) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.ORDER_POST_REQUEST });
    const order = createSelectedMenusSelector(getState());

    const { orderInfo, session } = getState();
    const {
      payment,
      name,
      phone,
      deliveryDate,
      deliveryMethod,
      remark,
      collaboration,
      promotionId,
    } = orderInfo;

    const _name = name ? name.split(' ') : [];
    const firstName = _name[0];
    const lastName = _name[1];

    const input = {
      address: {
        ...orderInfo.address,
        is_save: true, //!session.user || !(orderInfo.address && orderInfo.address.id),
        customer_name: name,
        phone: phone,
      },
      items: order.items.map(item => ({
        product: item.product.id,
        quantity: item.quantity,
      })),
      order_type: deliveryMethod,
      delivery_date: deliveryDate,
      remark: remark,
      collaboration: collaboration.id,
      promotion_id: promotionId,
      ...options,
    };

    if (payment && typeof payment === 'object') {
      input.checkout_method = {
        method: PaymentType.DEFAULT_CREDIT_CARD,
      };
    } else {
      input.checkout_method = {
        method: payment,
      };
    }

    return Promise.resolve()
      .then(() => {
        if (!firstName && !lastName) return Promise.reject('กรุณากรอกชื่อ');
        if (!phone) return Promise.reject('กรุณากรอกเบอร์โทร');
        if (!orderInfo.address) return Promise.reject('กรุณาระบุที่อยู่');
        if (!payment) return Promise.reject('กรุณาเลือกช่องทางการชำระเงิน');
        if (!session.user && !session.line && !session.facebook) {
          return Promise.reject('กรุณาเข้าสู่ระบบ');
        }
        return !session.user.first_name
          ? dispatch(updateMe(firstName, lastName))
          : Promise.resolve();
      })
      .then(() => {
        const requestOptions = {
          flow: options['chatroom'] ? 'doctor' : 'user',
          card: options['card']
        } 
        return Api.postOrder(input, membership, requestOptions);
      })
      .then(payload => {
        dispatch({ type: Constant.ORDER_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.ORDER_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const postOrderSummary = (options, membership) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.ORDER_SUMMARY_POST_REQUEST });
    const order = createSelectedMenusSelector(getState());

    const { orderInfo, session } = getState();
    const {
      name,
      phone,
      deliveryMethod,
      deliveryDate,
      collaboration,
      promotionId,
    } = orderInfo;
    const input = {
      address: {
        ...orderInfo.address,
        is_save: true, //!session.user || !(orderInfo.address && orderInfo.address.id),
        customer_name: name,
        phone: phone,
      },
      items: order.items.map(item => ({
        product: item.product.id,
        quantity: item.quantity,
      })),
      order_type: deliveryMethod,
      delivery_date: deliveryDate,
      collaboration: collaboration?.id,
      promotion_id: promotionId,
      ...options,
    };

    if (orderInfo.payment && typeof orderInfo.payment === 'object') {
      input.checkout_method = {
        method: 'default_credit_card',
      };
    }
    
    const requestOptions = {
      flow: options['chatroom'] ? 'doctor' : 'user',
      card: options['card']
    }
    return Api.postOrderSummary(input, membership, requestOptions)
      .then(payload => {
        dispatch({ type: Constant.ORDER_SUMMARY_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.ORDER_SUMMARY_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const getOrder = id => {
  return dispatch => {
    dispatch({ type: Constant.ORDER_GET_REQUEST, id });
    return Api.getOrder(id)
      .then(payload => {
        dispatch({ type: Constant.ORDER_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.ORDER_GET_FAILED, message });
      });
  };
};

export const getOrders = () => {
  return dispatch => {
    dispatch({ type: Constant.ORDERS_GET_REQUEST });
    return Api.getOrders()
      .then(payload => {
        dispatch({ type: Constant.ORDERS_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.ORDERS_GET_FAILED, message });
      });
  };
};
