import React from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { renderAppointmentDateTime, parseAppointmentDateTime } from '../../helpers/date';
import { navigateTo } from '../CrossPlatformLink';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PageLoader from '../PageLoader';
import NotificationEmptyState from '../NotificationEmptyState';

const useStyles = makeStyles(theme => ({
  menuImg: {
    width: '100%',
  },
  product: {
    background: '#fff6db',
    padding: 4,
  },
  root: {
    overflowX: 'hidden',
  },
  count: {
    display: 'flex',
    width: 35,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
  },
  outOfStock: {
    color: 'red',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 0
  },
  payment: {
    fontSize: '0.8em',
  },
  status_waiting_paid: {
    color: theme.palette.error.main,
  },
  status_paid: {
    color: theme.palette.secondary.main,
  },
}));

const paymentStatus = {
  'waiting_paid': 'รอการชำระ',
  'paid': 'ชำระแล้ว',
  'finished': 'ตรวจเสร็จแล้ว'
}

export default React.memo(({ loading, items }) => {
  const classes = useStyles();
  const { source } = useSelector(state => state.session);

  if (!items.length && loading) return <PageLoader />;
  if (!items.length) return (
    <NotificationEmptyState compact>
      <h3>ไม่พบรายการ</h3>
    </NotificationEmptyState>
  );

  return (
    <List>
      {
        items.map((item, index) => {
          const { id, package_items, payment_status, status, time_slot = {} } = item;
          return (
            <ListItem
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => navigateTo(source, `me.moronline://laborder/${id}`)}
            >
              <ListItemText
                primary={`${renderAppointmentDateTime(parseAppointmentDateTime(time_slot.date, time_slot.start_time))}`}
                secondary={package_items[0]?.labpackage?.name}
              />
              <ListItemSecondaryAction>
                {
                  status === 'finished' ? (
                    <small className={classes[`status_paid`]}>{paymentStatus[status]}</small>
                  ) : (
                    <small className={classes[`status_${payment_status}`]}>{paymentStatus[payment_status]}</small>
                  )
                }
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      }
    </List>
  );
});
