import React from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { closeWindow } from '../../apis/liff';

const map = (val) => {
  if (!val) return '';
  if (val.includes('me.moronline://exit')) return '/';
  if (val.includes('me.moronline://laborder')) return '/labStore/order/?id={param}';
  if (val.includes('me.moronline://order')) return '/drugStore/order/?id={param}';
  if (val.includes('me.moronline://chatroom')) return '/consult/patientApp/channel/?channelId={param}';
  if (val.includes('me.moronline://drugStore')) return 'https://drug.moronline.me/menu/?appToken={appToken}';
  if (val.includes('me.moronline://assessment/skipAi')) return '/assessment/personalInfo/?skipAi=1';
  if (val.includes('me.moronline://consult/category')) return '/consult/patientApp/chooseCategory?categoryId={param}';
  if (val.includes('me.moronline://consult')) return '/consult/patientApp/chooseCategory';
  if (val.includes('me.moronline://membership/order')) return '/membership/order?id={param}';
  if (val.includes('me.moronline://partners/consult/category')) return '/partners/consult/chooseConsultant{param}';
  if (val.includes('me.moronline://partners/consult')) return '/partners/consult/chooseCategory?partnerId={param}';
  if (val.includes('me.moronline.station://consult')) return '/consult/patientApp/chooseCategory';
  return '';
}

export function navigateTo(source, to, options) {
  if (!to) {
    return;
  }

  if (!source) {
    source = 'browser';
  }

  if (source === 'android' || source === 'ios' || source === 'station') {
    window.location.href = to;
    return;
  }

  if (source === 'liff' && to === 'me.moronline://exit') {
    closeWindow();
    return;
  }

  if (to.indexOf('http') === 0) {
    window.location.href = to;
    return;
  }

  let _to = map(to);

  if (!_to) {
    navigate(to, options);
    return;
  }

  if (localStorage) {
    _to = _to.replace('{appToken}', localStorage.getItem('token'));
  }
  if (_to.includes('{param}')) {
    const param = to.slice(to.lastIndexOf('/') + 1);
    _to = _to.replace('{param}', param);
  }

  if (_to.indexOf('http') === 0) {
    window.location.href = _to;
    return;
  }
  
  navigate(_to);
}

export default ({ to, params, children }) => {
  const { source } = useSelector(state => state.session);

  const onClick = (e) => {
    e.preventDefault();
    navigateTo(source, to, params);
  }
  
  return <a onClick={onClick}>{children}</a>;
}
