import * as Api from '../apis';

var Twilio;
let client;
let TOKEN;

const addScript = (src, id) => {
  return new Promise((resolve, reject) => {
    const existing = document.getElementById(id);
    if (existing) {
      resolve();
    } else {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => {
        resolve();
      };
      document.body.appendChild(script);
    }
  });
};

export const init = async () => {
  if (client) {
    return client.user;
  }

  await addScript(
    'https://media.twiliocdn.com/sdk/js/chat/releases/5.0.2/twilio-chat.min.js',
    'twilio-script',
  );
  const payload = await Api.logInTwilio();
  TOKEN = payload.token;
  Twilio = window.Twilio;

  client = await Twilio.Chat.Client.create(TOKEN, { logLevel: 'info' });
  window.client = client;
  client.on('tokenAboutToExpire', () => {
    Api.logInTwilio().then(payload => {
      TOKEN = payload.token;
      client.updateToken(TOKEN);
    });
  });

  // client.user.on('updated', function () {
  // });

  // client.on('channelJoined', function (channel) {
  //   channel.on('messageAdded', console.log);
  //   channel.on('messageAdded', console.log);
  // });

  // client.on('channelInvited', console.log);
  // client.on('channelAdded', console.log);
  // client.on('channelUpdated', console.log);
  // client.on('channelLeft', console.log);
  // client.on('channelRemoved', console.log);

  return client.user;
};

export const getClient = () => client;

export const getChannel = sid => {
  return client.getChannelBySid(sid);
};

export const getChannelByUniqueName = name => {
  return client.getChannelByUniqueName(name);
};

export const getChannels = () => {
  return client.getSubscribedChannels();
};

export const getChannelMessages = (activeChannel, count = 100) => {
  return activeChannel.getMessages(count);
};

export const createMessage = async (activeChannel, message) => {
  await activeChannel.sendMessage(message);
  return activeChannel;
};

export const deleteChannel = activeChannel => {
  return activeChannel.delete();
};

export const createChannel = (uniqueName, friendlyName) => {
  return client
    .createChannel({ uniqueName, friendlyName })
    .then(channel => channel.join());
};

export const getLastMessageByChannelSid = async sid => {
  const ch = await getChannel(sid);
  const lastMessage = await getChannelMessages(ch, 1);
  return lastMessage.items[0];
};

export const getPublicChannelDescriptors = () => {
  return client.getPublicChannelDescriptors();
};

export const getUserChannelDescriptors = () => {
  return client.getUserChannelDescriptors();
};

export const joinChannel = channelId => {
  return getChannel(channelId).then(channel => {
    return channel.join().catch(() => {
      return channel;
    });
  });
};

export const getChannelUsers = channel => {
  return channel.getMembers().then(members => {
    return Promise.all(members.map(member => member.getUserDescriptor()));
  });
};
