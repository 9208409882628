import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { displayCurrency } from '../../helpers/currency';
import { navigateTo } from '../CrossPlatformLink';
import { renderAppointmentDateTime } from '../../helpers/date';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  status_waiting_paid: {
    color: theme.palette.error.main,
  },
  status_paid: {
    color: theme.palette.secondary.main,
  },
}));

const paymentStatus = {
  'waiting_paid': 'รอการชำระ',
  'paid': 'ชำระแล้ว',
  'finished': 'ตรวจเสร็จแล้ว'
}

export default React.memo(({ items }) => {
  const classes = useStyles();
  const { source } = useSelector(state => state.session);

  return (
    <List>
      {
        items.map((val, index) => {
          const { payment_status } = val;
          return (
            <ListItem
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => navigateTo(source, `me.moronline://order/${val.id}`)}
            >
              <ListItemText
                primary={renderAppointmentDateTime(val.created)}
                secondary={val.items[0]?.product?.name}
              />
              <ListItemSecondaryAction>
                <Box display="flex" flexDirection="column" textAlign="end">
                  <span>{displayCurrency(val.grand_total_price)}</span>
                  <span className={classes[`status_${payment_status}`]}>{paymentStatus[payment_status]}</span>
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      }
    </List>
  );
});
