import React from 'react'
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';

export default React.memo(({ noAvatar }) =>
  [...new Array(3)].map((_, index) => (
    <ListItem alignItems="flex-start" key={index}>
      {
        !noAvatar && (
          <ListItemAvatar>
            <Skeleton variant="circle" width={40} height={40} />
          </ListItemAvatar>
        )
      }
      <ListItemText primary={<Skeleton variant="rect" />} secondary={<Skeleton variant="rect" />} />
    </ListItem>
  ))
);
