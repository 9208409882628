// example response
// {
//   api_created: true,
//   created_at: "2021-08-07T10:12:10.740Z",
//   id: "38790b09-a350-41a2-acba-0f4bb010cc24",
//   name: "Z9ZrU4VeOykToGcIeymu",
//   privacy: "private",
//   token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoiWjlaclU0VmVPeWtUb0djSWV5bXUiLCJkIjoiNzAzOWIxY2EtZDJkZC00NjE2LTk3MTktYTY0ZmY4ZTVlN2M1IiwiaWF0IjoxNjI4MzMxMTMxfQ.GmyHW8yGYu1mn-7XwasopcUHEgha1tysFTW230a7aB8",
//   url: "https://moronline.daily.co/Z9ZrU4VeOykToGcIeymu",
// }
export const createVideoRoom = async () => {
  const room = await createRoom();
  const token = await getRoomToken(room.name);
  return Object.assign(room, token);
};

// example response
// {
//   api_created: true,
//   created_at: "2021-08-07T10:12:10.740Z",
//   id: "38790b09-a350-41a2-acba-0f4bb010cc24",
//   name: "Z9ZrU4VeOykToGcIeymu",
//   privacy: "private",
//   token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoiWjlaclU0VmVPeWtUb0djSWV5bXUiLCJkIjoiNzAzOWIxY2EtZDJkZC00NjE2LTk3MTktYTY0ZmY4ZTVlN2M1IiwiaWF0IjoxNjI4MzMxMTMxfQ.GmyHW8yGYu1mn-7XwasopcUHEgha1tysFTW230a7aB8",
//   url: "https://moronline.daily.co/Z9ZrU4VeOykToGcIeymu",
// }
export const createVideoRoomWithName = async name => {
  let token;
  let room = await getRoomWithName(name);
  if (room.error) {
    room = await createRoom(name);
  }
  token = await getRoomToken(room.name);
  return Object.assign(room, token);
};

export const deleteVideoRoom = async name => {
  return deleteRoomWithName(name);
};

const createRoom = async name => {
  const body = {
    privacy: 'private',
    properties: {
      enable_prejoin_ui: false,
      enable_chat: false,
      enable_screenshare: false,
      enable_recording: `${process.env.GATSBY_DAILY_RECORDING_TYPE}`,
      recordings_bucket: {
        bucket_name: `${process.env.GATSBY_DAILY_RECORDING_BUCKET_NAME}`,
        bucket_region: `${process.env.GATSBY_DAILY_RECORDING_BUCKET_REGION}`,
        assume_role_arn: `${process.env.GATSBY_DAILY_RECORDING_ROLE_ARN}`,
        allow_api_access: false
      }
    },
  };
  if (name) {
    body.name = name;
  }
  try {
    let response = await fetch(`https://api.daily.co/v1/rooms`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_DAILY_TOKEN}`,
      },
      body: JSON.stringify(body),
      mode: 'cors',
    });
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getRoomToken = async name => {
  try {
    let response = await fetch(`https://api.daily.co/v1/meeting-tokens`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_DAILY_TOKEN}`,
      },
      body: JSON.stringify({
        properties: {
          room_name: name,
          start_cloud_recording: true,
        },
      }),
      mode: 'cors',
    });
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getRoomWithName = async name => {
  try {
    let response = await fetch(`https://api.daily.co/v1/rooms/${name}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_DAILY_TOKEN}`,
      },
      mode: 'cors',
    });
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const deleteRoomWithName = async name => {
  try {
    let response = await fetch(`https://api.daily.co/v1/rooms/${name}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_DAILY_TOKEN}`,
      },
      mode: 'cors',
    });
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
};
