import React from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ConsentNews from '../../components/consentNews';

const useStyles = makeStyles(theme => ({
  appBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 70,
    boxShadow: '0px 0px 6px #3e3e3e',
    zIndex: 1
  },
  container: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      background: `url(/img/login-bg.png)`,
      backgroundPosition: 'top',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    background: `url(/img/desktop_bg.png)`,
    backgroundPosition: 'top',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
  },
  menuIcon: {
    margin: '15px',
    fontSize: '28px'
  },
  appIcon: {
    margin: '10px',
    width: '60px',
    height: '60px',
  },
}));

const Layout = ({ children, hideAppBar }) => {
  const classes = useStyles();

  return (
    <>
      <ConsentNews />
      <Grid
        container
        spacing={0}
        direction="column"
        style={{ height: '100vh', width: '100vw' }}
      >
        {
          !hideAppBar && (
            <Grid item className={classes.appBar}>
              <img src="/img/appLogo.png" className={classes.appIcon} alt="apps logo" />
              <MenuIcon className={classes.menuIcon} />
            </Grid>
          )
        }
        <Grid item className={classes.container}>
          {children}
        </Grid>
      </Grid>
    </>
  );
}

export default Layout;

