import React from 'react';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { getUrlParams } from '../../helpers/location';
import { createLoadingSelector } from '../../reducers/api';
import { createAllChatroomsSelector } from '../../reducers/chatrooms';
import { createLabOrdersSelector } from '../../reducers/lab';
import { createOrdersSelector } from '../../reducers/order';
import { getChatrooms } from '../../actions/chatrooms';
import { getLabOrders } from '../../actions/lab';
import { getOrders } from '../../actions/orders';
import Helmet from 'react-helmet';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Layout from '../../components/Layout';
import LoaderView from '../../components/LoaderView';
import HistoryTabView from '../../components/HistoryTabView';
import { sortByDate } from '../../helpers/date';

const MENU = [
  'นัดหมายแพทย์ใหม่',
  'ประวัติพบแพทย์ย้อนหลัง',
  '-',
  'นัดหมายตรวจแล็บใหม่',
  'ประวัติตรวจแล็บ',
  '-',
  'ประวัติการสั่งซื้อยา',
  // '-',
  // 'ประวัติการทำการประเมิน a.i.',
  // 'ประวัติการทำการประเมินสุขภาพจิต',
  // 'ประวัติการทำการประเมินพัฒนาการเด็ก'
];

export default ({ location }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tab, setTab] = React.useState();
  const [list, setList] = React.useState({});
  const dispatch = useDispatch();
  const query = getUrlParams(location.search);
  const isLoading = useSelector(createLoadingSelector(['chatrooms/get', 'lab/orders/get', 'orders/get']));
  const chatrooms = useSelector(createAllChatroomsSelector);
  const labOrders = useSelector(createLabOrdersSelector);
  const orders = useSelector(createOrdersSelector);
  const session = useSelector(state => state.session);

  React.useEffect(() => {
    setList({
      0: chatrooms.sort(sortByDate).filter(val => !val.closed),
      1: chatrooms.sort(sortByDate).filter(val => val.closed),
      3: labOrders.filter(val => !val.result),
      4: labOrders.filter(val => val.result),
      6: orders,
    });
  }, [chatrooms, labOrders, orders]);

  React.useEffect(() => {
    setTab(Number(query.tab || '0'));
  }, [query.tab]);

  React.useEffect(() => {
    if (tab === 0) {
      dispatch(getChatrooms(false, 'paid'));
    } else if (tab === 1) {
      dispatch(getChatrooms(false, 'paid'));
    } else if (tab === 3) {
      dispatch(getLabOrders());
    } else if (tab === 4) {
      dispatch(getLabOrders());
    } else if (tab === 6) {
      dispatch(getOrders());
    }
  }, [tab]);

  const tabChange = (val) => () => {
    handleClose();
    navigate(`/history?tab=${val}&appToken=${session.user.token}&source=${session.source}`, { replace: true });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const listItems = list[tab] || [];
  return (
    <Layout hideAppBar>
      <Helmet>
        <title>ประวัติ</title>
      </Helmet>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          MENU.map((val, index) => (
            val === '-' ? <Divider key={index} /> : <MenuItem key={index} onClick={tabChange(index)}>{val}</MenuItem>
          ))
        }
      </Menu>

      <Box p={2}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ExpandMoreIcon />}
          onClick={handleClick}
        >
          {MENU[tab]}
        </Button>
        <LoaderView loading={isLoading} empty={!isLoading && listItems.length === 0}>
          <HistoryTabView tab={tab} list={listItems}/>
        </LoaderView>
      </Box>
    </Layout>
  );
}
